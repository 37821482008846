import React, { Component, Fragment } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import "video-react/dist/video-react.css";
import { Player, BigPlayButton } from "video-react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";

class CourseDetails extends Component {
	constructor(props) {
		super();
		this.state = {
			MyCourseId: props.id,
			LongTitle: "",
			LongDescription: "",
			TotalDuration: "",
			TotalLecture: "",
			TotalStudent: "",
			SkillAll: "",
			VideoUrl: "",
			SmallImg: "",
		};
	}
	componentDidMount() {
		RestClient.GetRequest(AppUrl.CourseDetails + this.state.MyCourseId).then(
			(result) => {
				this.setState({
					LongTitle: result[0]["long_title"],
					LongDescription: result[0]["long_description"],
					SmallImg: result[0]["small_img"],
					TotalDuration: result[0]["total_duration"],
					TotalLecture: result[0]["total_lecture"],
					TotalStudent: result[0]["total_student"],
					SkillAll: result[0]["skill_all"],
					VideoUrl: result[0]["video_url"],
				});
			},
		);
	}

	render() {
		return (
			<Fragment>
				<Container className="mt-5">
					<Row>
						<Col lg={8} md={6} sm={12}>
							<img
								className="courseDetaisImg"
								// src="https://solverwp.com/demo/html/edumint/assets/img/course/9.png"
								src={this.state.SmallImg}
							/>
							<h1 className="coruseDetailsText">{this.state.LongTitle}</h1>
							<br></br> <br></br>
							<p className="CoruseallDescription">
								{this.state.LongDescription}
							</p>
						</Col>

						<Col lg={4} md={6} sm={12}>
							<div className="widget_feature">
								<h4 class="widget-title text-center">Course Features</h4>
								<hr />
								<ul>
									<li>
										<FontAwesomeIcon className="iconBullent" icon={faUser} />{" "}
										<span> Enrolled :</span> 1200 students
									</li>

									<li>
										<FontAwesomeIcon className="iconBullent" icon={faClock} />{" "}
										<span>Duration :</span> {this.state.TotalDuration}
									</li>

									<li>
										<FontAwesomeIcon
											className="iconBullent"
											icon={faClipboard}
										/>{" "}
										<span>Lectures :</span> {this.state.TotalLecture}
									</li>

									<li>
										<FontAwesomeIcon className="iconBullent" icon={faClone} />{" "}
										<span>Categories:</span> Technology
									</li>

									<li>
										<FontAwesomeIcon className="iconBullent" icon={faTags} />{" "}
										<span>Tags:</span> Android, JavaScript
									</li>

									<li>
										<FontAwesomeIcon
											className="iconBullent"
											icon={faCheckSquare}
										/>{" "}
										<span>Instructor:</span> {this.state.TotalStudent}
									</li>
								</ul>
								<div class="price-wrap text-center">
									<h5>
										Price:<span>$54.00</span>
									</h5>
									<Button className="enrollCourceBtn">ENROLL COURSE</Button>
								</div>
							</div>
						</Col>
					</Row>
				</Container>

				<br></br>
				<br></br>
				<Container>
					<Row>
						<Col lg={6} md={6} sm={12}>
							<div className="widget_feature">
								<h1 className="coruseDetailsText"> Skill You Need </h1>
								<hr />
								<ul>
									<li>
										<FontAwesomeIcon
											className="iconBullent"
											icon={faCheckSquare}
										/>{" "}
										Proin eget tortor risus.
									</li>
									<li>
										<FontAwesomeIcon
											className="iconBullent"
											icon={faCheckSquare}
										/>{" "}
										Nulla quis lorem ut libero malesuada feugiat.
									</li>

									<li>
										<FontAwesomeIcon
											className="iconBullent"
											icon={faCheckSquare}
										/>{" "}
										Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
									</li>

									<li>
										<FontAwesomeIcon
											className="iconBullent"
											icon={faCheckSquare}
										/>
										Pellentesque in ipsum id orci porta dapibus.
									</li>

									<li>
										<FontAwesomeIcon
											className="iconBullent"
											icon={faCheckSquare}
										/>{" "}
										Curabitur non nulla sit
									</li>
								</ul>
							</div>
						</Col>

						<Col lg={6} md={6} sm={12}>
							<Player src={this.state.VideoUrl}>
								<BigPlayButton position="center" />
							</Player>
						</Col>
					</Row>
				</Container>
			</Fragment>
		);
	}
}

export default CourseDetails;
