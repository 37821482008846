import React from "react";
import "./asset/css/bootstrap.min.css";
import "./asset/css/custom.css";
import "./asset/css/responsive.css";

import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";

function App() {
	return (
		<div>
			<BrowserRouter>
				<AppRouter />
			</BrowserRouter>
		</div>
	);
}

export default App;
