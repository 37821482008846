class AppUrl {
	static BaseURL = "https://abbas.aimticks.com/api";

	static HomeTopTitle = this.BaseURL + "/homepage/title";
	static HomeTechDesc = this.BaseURL + "/techhome";
	static TotalHomeDetails = this.BaseURL + "/totalhome";
	static HomeVideo = this.BaseURL + "/home/video";

	static ProjectDetails = this.BaseURL + "/projectdetails/";
	static ProjectAll = this.BaseURL + "/projectall";
	static ProjectHome = this.BaseURL + "/projecthome";
	static Services = this.BaseURL + "/services";

	static Information = this.BaseURL + "/information";
	static FooterData = this.BaseURL + "/footerdata";

	static ContactSend = this.BaseURL + "/contactsend";
	static ClientReview = this.BaseURL + "/clientreview";
	static ChartData = this.BaseURL + "/chartdata";
}

export default AppUrl;
