import React, { Component, Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import AboutPage from "../pages/AboutPage";
import AllCorusePage from "../pages/AllCorusePage";
import AllServicePage from "../pages/AllServicePage";
import ContactPage from "../pages/ContactPage";
import CourseDetailsPage from "../pages/CourseDetailsPage";
import HomePage from "../pages/HomePage";
import PortfolioPage from "../pages/PortfolioPage";
import PrivacyPage from "../pages/PrivacyPage";
import ProjectDetailPage from "../pages/ProjectDetailPage";
import RefundPage from "../pages/RefundPage";
import TremsPage from "../pages/TremsPage";
import PageNotFound from "../pages/PageNotFound";

class AppRouter extends Component {
	render() {
		return (
			<>
				<Routes>
					<Route exact path="/" element={<HomePage />}></Route>
					<Route exact path="/service" element={<AllServicePage />}></Route>
					<Route exact path="/course" element={<AllCorusePage />}></Route>
					<Route exact path="/aimticks" element={<PortfolioPage />}></Route>
					<Route exact path="/about" element={<AboutPage />}></Route>
					<Route exact path="/contact" element={<ContactPage />}></Route>
					<Route exact path="/refund" element={<RefundPage />}></Route>
					<Route exact path="/trems" element={<TremsPage />}></Route>
					<Route exact path="/privacy" element={<PrivacyPage />}></Route>
					<Route
						exact
						path="/projectdetails/:projectID/:projectName"
						element={<ProjectDetailPage />}
					></Route>
					{/* <Route
						exact
						path="/coursedetails/:courseID/:courseName"
						element={<CourseDetailsPage />}
					></Route> */}
					<Route element={<PageNotFound />}></Route>
				</Routes>
			</>
		);
	}
}

export default AppRouter;
