import React, { Component, Fragment, useState, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import ProjectDetails from "../components/ProjectDetails/ProjectDetails";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import { useParams } from "react-router-dom";

const ProjectDetailPage = () => {
	// const [id, setId] = useState("");
	const { projectID, projectName } = useParams();
	useEffect(() => {
		window.scroll(0, 0);
	}, []);
	return (
		<>
			<TopNavigation title="Project Details " />

			<PageTop pagetitle={projectName} />
			<ProjectDetails id={projectID} />

			<Footer />
		</>
	);
};
export default ProjectDetailPage;
